<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="hegiForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('hegiForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
      </div>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">收款登记单号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="hegiForm.hegi_no" show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="hegiForm" :model="hegiForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="登记单类型" prop="x">
            <span>H K</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="汇款客户" prop="cust_abbr">
            <el-input disabled v-model="hegiForm.cust_abbr" maxlength="100" show-word-limit placeholder="请填写汇款公司">
              <template slot="append">
                <div class="flexHV">
                  <el-link disabled class="vg_mr_8">{{ hegiForm.cust_group }}</el-link>
                  <el-link type="primary" :disabled="isShow" @click="dialogTableVisible = true">导入</el-link>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款方式" prop="hegi_type">
            <el-select v-model="hegiForm.hegi_type" size="small" placeholder="请选择收款方式" filterable>
              <el-option v-for="item in hegiTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款日期:" prop="hegi_date">
            <el-date-picker v-model="hegiForm.hegi_date" format="yyyy-MM-dd" placeholder="选择收款日期" type="date" value-format="timestamp"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="收款金额" prop="hegi_total">
            <el-input
              v-model="hegiForm.hegi_total"
              @change="calcEcho"
              @input="val => (hegiForm.hegi_total = keep2Decimal(val))"
              maxlength="13"
              show-word-limit
              placeholder="请填写收款金额"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select v-model="hegiForm.cust_currency" size="small" placeholder="请选择币种" filterable>
              <el-option v-for="item in CompanyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款银行" prop="cptt_bank_name">
            <el-select
              @change="bankChange"
              :disabled="!hegiForm.cptt_aid"
              v-model="hegiForm.cptt_bank_name"
              size="small"
              placeholder="请选择收款银行"
              filterable
            >
              <el-option v-for="item in bankList" :key="item.cptt_bank_id" :label="item.cptt_bank_name" :value="item.cptt_bank_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--        <el-col :md="8">-->
        <!--          <el-form-item label="汇率" prop="tran_usdrate">-->
        <!--            <el-input-->
        <!--              v-model="hegiForm.tran_usdrate"-->
        <!--              @change="calcEcho"-->
        <!--              @input="hegiForm.tran_usdrate = helper.keepTNum2(hegiForm.tran_usdrate)"-->
        <!--              @blur="hegiForm.tran_usdrate = helper.calcPrice(hegiForm.tran_usdrate, 4, 100)"-->
        <!--              maxlength="7"-->
        <!--              show-word-limit-->
        <!--              placeholder="请填写汇率"-->
        <!--            >-->
        <!--            </el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
      <el-row class="vg_mb_5">
        <!--        <el-col :md="8">-->
        <!--          <el-form-item label="本币总额" prop="scon_rmbtotal">-->
        <!--            <el-input disabled v-model="hegiForm.scon_rmbtotal" maxlength="30" show-word-limit placeholder="请填写本币总额"> </el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <el-col :md="8">
          <el-form-item label="收款抬头" prop="cptt_aid">
            <el-select @change="cpttChange(1)" v-model="hegiForm.cptt_aid" size="small" placeholder="请选择公司名称" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款单情况" prop="hegi_situ">
            <el-select disabled v-model="hegiForm.hegi_situ" size="small" placeholder="请选择收款单情况" filterable>
              <el-option v-for="item in situList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="银行账户" prop="cptt_bank_account">
            <el-input disabled v-model="hegiForm.cptt_bank_account" show-word-limit placeholder="请填写银行账户"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="汇款抬头" prop="cptt_bid">
            <el-select v-model="hegiForm.cptt_bid" size="small" placeholder="请选择汇款公司" show-word-limit clearable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog title="导入汇款客户" width="70%" :visible.sync="dialogTableVisible">
        <CustList @importCustChange="importCustChange"></CustList>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="hegiForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { hegiAPI } from '@api/modules/hegi';
import { optnAPI } from '@api/modules/optn';
import { cpttAPI } from '@api/modules/comptitle';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import CustList from './Component/CustList.vue';
import { keep2Decimal } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'hegiEditMain',
  components: {
    editHeader,
    inputUser,
    CustList
  },
  data() {
    return {
      rules: {
        cust_abbr: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_aid: [{ required: true, trigger: 'blur', message: ' ' }],
        hegi_total: [{ required: true, trigger: 'blur', message: ' ' }],
        hegi_date: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      hegiForm: {},
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      hegiTypeList: [
        { id: 1, label: '电汇' },
        { id: 2, label: '现金' },
        { id: 3, label: '信用证' },
        { id: 4, label: '承兑' }
      ],
      CompanyList: [],
      cpttList: [],
      situList: [
        { id: 0, label: '未生成' },
        { id: 1, label: '已生成收款单' },
        { id: 2, label: '已生成预收单' }
      ],
      bankList: [],
      dialogTableVisible: false
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    keep2Decimal,
    initData() {
      this.loadingFlag = true;
      this.getHegiInfo();
      this.getCptt();
      this.getCustDport();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let hegiForm = JSON.parse(JSON.stringify(this.hegiForm));
      post(hegiAPI.editHegi, hegiForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 选择汇款公司
    importCustChange(val) {
      this.hegiForm.cust_id = val.cust_id;
      this.hegiForm.cust_abbr = val.cust_name;
      this.hegiForm.cust_group = val.cust_group;
      this.dialogTableVisible = false;
    },
    // 公司回显银行选项
    cpttChange(val) {
      for (let i = 0; i < this.cpttList.length; i++) {
        if (this.cpttList[i].cptt_id === this.hegiForm.cptt_aid) {
          this.bankList = this.cpttList[i].cptt_bank_list;
          // this.hegiForm.cptt_aname = this.cpttList[i].cptt_name
          if (val === 1) {
            this.hegiForm.cptt_aname = this.cpttList[i].cptt_name;
            if (this.cpttList[i].cptt_bank_list.length > 0) {
              this.hegiForm.cptt_bank_name = this.bankList[0].cptt_bank_name;
              this.bankChange();
            } else {
              this.hegiForm.cptt_bank_name = null;
              this.hegiForm.cptt_bank_account = null;
            }
          }
        }
      }
    },
    // 回显银行账户
    bankChange() {
      for (let i = 0; i < this.bankList.length; i++) {
        if (this.bankList[i].cptt_bank_name === this.hegiForm.cptt_bank_name) {
          this.hegiForm.cptt_bank_account = this.bankList[i].cptt_bank_code;
        }
      }
    },
    // 计算回显值
    calcEcho() {
      let totalA = new BigNumber(1);
      totalA = totalA.times(this.hegiForm.hegi_total ? this.hegiForm.hegi_total : 0).times(this.hegiForm.tran_usdrate ? this.hegiForm.tran_usdrate : 0);
      totalA = totalA.toNumber();
      this.hegiForm.scon_rmbtotal = this.helper.haveFour(totalA);
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.CompanyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getHegiInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(hegiAPI.getHegiById, { hegi_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.hegiForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.hegiForm.stff_name;
            this.stffForm.dept_name = this.hegiForm.dept_name;
            this.stffForm.dept_team_name = this.hegiForm.dept_team_name;
            this.stffForm.user_id = this.hegiForm.user_id;
            this.stffForm.dept_id = this.hegiForm.dept_id;
            this.stffForm.stff_id = this.hegiForm.stff_id;
            this.cpttChange(0);
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_word {
  word-wrap: break-word;
  word-break: normal;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>

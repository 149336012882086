<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'">
        <childTab2 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3 />
      </el-tab-pane>
      <el-tab-pane label="关联信息" name="four" :key="'four'">
        <el-link
          @click="
            jump('soll_list', {
              perm_id: 144,
              cust: JSON.parse($UrlEncode.decode($route.query.key)).cust,
              type: JSON.parse($UrlEncode.decode($route.query.key)).type
            })
          "
        >
          收款单
        </el-link>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import HegiEditMain from '@/views/FinanceManagement/HegiManage/TabChild/HegiEditMain';
import HegiEditAppr from '@/views/FinanceManagement/HegiManage/TabChild/HegiEditAppr';
import HegiEditAbout from '@/views/FinanceManagement/HegiManage/TabChild/HegiEditAbout';
export default {
  name: 'HegiEdit',
  components: {
    childTab1: HegiEditMain,
    childTab2: HegiEditAppr,
    childTab3: HegiEditAbout
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped></style>
